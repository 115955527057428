import axios from "@/axios.js";

export default {
  async fetchBusFeatures({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/bus-feature`);
      commit("setBusFeatures", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchBusFeature({ commit }, params) {
    try {
      const { data } = await axios.get(`/vancynet/bus-feature/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeBusFeature({ commit }, params) {
    try {
      const { data } = await axios.post(`/vancynet/bus-feature`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateBusFeature({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/bus-feature/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeBusFeature({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/vancynet/bus-feature/${params}`);
      dispatch("fetchBusFeatures");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
