var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ direction: _vm.$vs.rtl ? 'rtl' : 'ltr' })},[(
      _vm.$store.state.AppActiveUser.userInfo.roles[0].permissions
        .map((permission) => permission.name)
        .includes('update_meal')
    )?_c('feather-icon',{attrs:{"icon":"Edit3Icon","svgClasses":"h-5 w-5 mr-4 hover:text-primary cursor-pointer"},on:{"click":_vm.editRecord}}):_vm._e(),(
      _vm.$store.state.AppActiveUser.userInfo.roles[0].permissions
        .map((permission) => permission.name)
        .includes('delete_meal')
    )?_c('feather-icon',{attrs:{"icon":"Trash2Icon","svgClasses":"h-5 w-5 hover:text-danger cursor-pointer"},on:{"click":_vm.confirmDeleteRecord}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }